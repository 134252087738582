import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./Theme";

const ThemeContext = createContext({
    toggleTheme: () => {},
    themeMode: "dark"
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    // Initialize themeMode from localStorage or fallback to 'dark'
    const [themeMode, setThemeMode] = useState<"light" | "dark">(() => {
        return (localStorage.getItem("themeMode") as "light" | "dark") || "dark";
    });

    useEffect(() => {
        const root = document.documentElement;
        const theme = themeMode === "light" ? lightTheme : darkTheme;

        // Apply CSS variables only if they differ
        root.style.setProperty("--background-color", theme.palette.background.default);
        root.style.setProperty("--text-color", theme.palette.text.primary);
        root.style.setProperty("--logo-background-color", theme.custom.logoBackgroundColor);
        root.style.setProperty("--background-waves", theme.custom.backgroundWaves);

        // Avoid unnecessary writes to localStorage
        const currentTheme = localStorage.getItem("themeMode");
        if (currentTheme !== themeMode) {
            localStorage.setItem("themeMode", themeMode);
        }
    }, [themeMode]);

    const toggleTheme = () => {
        setThemeMode(prev => (prev === "light" ? "dark" : "light"));
    };

    const theme = themeMode === "light" ? lightTheme : darkTheme;

    return (
        <ThemeContext.Provider value={{ toggleTheme, themeMode }}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
