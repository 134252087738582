import { createTheme } from "@mui/material/styles";

// Extend the Material-UI Theme
declare module "@mui/material/styles" {
    interface Theme {
        custom: {
            logoBackgroundColor: string;
            backgroundWaves: string;
        };
    }
    interface ThemeOptions {
        custom?: {
            logoBackgroundColor?: string;
            backgroundWaves: string;
        };
    }
}

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "linear-gradient(180deg, #eef3f8, #ffffff)"
        },
        text: {
            primary: "#000"
        }
    },
    custom: {
        logoBackgroundColor: "linear-gradient(90deg, #838181, #0e0e0f)", // Add a dark theme logo background color
        backgroundWaves:
            "url('data:image/svg+xml,%3Csvg width=%271496%27 height=%27200%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg opacity=%270.08%27 clipPath=%27url(%23waves_bottom)%27 fill=%27%23000%27%3E%3Cpath d=%27M1569.5 120.707v.484C1016.72-180.723 463.932 230.405-88.854 40.569v-4.062C463.932 228.554 1016.72-180.42 1569.5 120.707Z%27/%3E%3Cpath d=%27M1569.5 126.488v.508C1016.72-160.002 463.932 265.262-88.854 90.758v-4.166C463.932 263.33 1016.72-160.699 1569.5 126.488Z%27/%3E%3Cpath d=%27M1569.5 132.454v.508c-552.78-270.77-1105.568 170.976-1658.354 10.127v-4.246C463.932 295.949 1016.72-140.55 1569.5 132.454Z%27/%3E%3Cpath d=%27M1569.5 138.454v.519C1016.72-120.234 463.932 335.248-88.854 190.354v-4.327C463.932 332.201 1016.72-120 1569.5 138.454Z%27/%3E%3Cpath d=%27M452.794 214C825.026 140.224 1197.25-20.213 1569.5 145.57v.577C1201.9-16.786 834.332 140.539 466.764 214h-13.97Z%27/%3E%3Cpath d=%27M590.841 214c326.213-88.162 652.429-200.868 978.659-64.233v.531C1246.58 18.775 923.676 130.584 600.78 214h-9.939Z%27/%3E%3Cpath d=%27M697.068 214c290.8-88.643 581.612-172.02 872.432-58.99v.519C1281.41 46.243 993.327 130.669 705.256 214h-8.188Z%27/%3E%3Cpath d=%27M787.368 214c260.712-80.45 521.402-144.944 782.132-50.724v.531C1311.18 70.064 1052.88 134.116 794.566 214h-7.198Z%27/%3E%3Cpath d=%27M867.948 214c233.842-74.949 467.692-124.557 701.552-45.435v.542C1337.8 94.22 1106.13 142.766 874.455 214h-6.507Z%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27waves_bottom%27%3E%3Cpath fill=%27%23000%27 d=%27M0 0h1496v214H0z%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E')"
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#0e0e0f"
        },
        text: {
            primary: "#fff"
        }
    },
    custom: {
        logoBackgroundColor: "linear-gradient(90deg, #838181, #fff)", // Add a dark theme logo background color
        backgroundWaves:
            "url('data:image/svg+xml,%3Csvg width=%271496%27 height=%27200%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg opacity=%270.08%27 clipPath=%27url(%23waves_bottom)%27 fill=%27%23fff%27%3E%3Cpath d=%27M1569.5 120.707v.484C1016.72-180.723 463.932 230.405-88.854 40.569v-4.062C463.932 228.554 1016.72-180.42 1569.5 120.707Z%27/%3E%3Cpath d=%27M1569.5 126.488v.508C1016.72-160.002 463.932 265.262-88.854 90.758v-4.166C463.932 263.33 1016.72-160.699 1569.5 126.488Z%27/%3E%3Cpath d=%27M1569.5 132.454v.508c-552.78-270.77-1105.568 170.976-1658.354 10.127v-4.246C463.932 295.949 1016.72-140.55 1569.5 132.454Z%27/%3E%3Cpath d=%27M1569.5 138.454v.519C1016.72-120.234 463.932 335.248-88.854 190.354v-4.327C463.932 332.201 1016.72-120 1569.5 138.454Z%27/%3E%3Cpath d=%27M452.794 214C825.026 140.224 1197.25-20.213 1569.5 145.57v.577C1201.9-16.786 834.332 140.539 466.764 214h-13.97Z%27/%3E%3Cpath d=%27M590.841 214c326.213-88.162 652.429-200.868 978.659-64.233v.531C1246.58 18.775 923.676 130.584 600.78 214h-9.939Z%27/%3E%3Cpath d=%27M697.068 214c290.8-88.643 581.612-172.02 872.432-58.99v.519C1281.41 46.243 993.327 130.669 705.256 214h-8.188Z%27/%3E%3Cpath d=%27M787.368 214c260.712-80.45 521.402-144.944 782.132-50.724v.531C1311.18 70.064 1052.88 134.116 794.566 214h-7.198Z%27/%3E%3Cpath d=%27M867.948 214c233.842-74.949 467.692-124.557 701.552-45.435v.542C1337.8 94.22 1106.13 142.766 874.455 214h-6.507Z%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27waves_bottom%27%3E%3Cpath fill=%27%23fff%27 d=%27M0 0h1496v214H0z%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E')" // Correctly embedded SVG
    }
});
