import { useRef, useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Checkbox, Panel, DefaultButton, TextField, ITextFieldProps, ICheckboxProps, Label } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Home.module.css";

import {
    chatApi,
    persistSettings,
    getSettings,
    configApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput,
    SpeechConfig,
    ChatAppRequestOverrides
} from "../../api";
import { LoginButton } from "../../components/LoginButton";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { ExampleList } from "../../components/Example";
import { HelpCallout } from "../../components/HelpCallout";
import { useLogin, getId, getToken, getTokenClaims, requireAccessControl, requireLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { LoginContext } from "../../loginContext";
import { LanguagePicker } from "../../i18n/LanguagePicker";
import { useTheme } from "../../theme/ThemeContext";
const Home = () => {
    const { toggleTheme, themeMode } = useTheme();
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [speechUrls, setSpeechUrls] = useState<(string | null)[]>([]);

    const client = useLogin ? useMsal().instance : undefined;

    const { loggedIn } = useContext(LoginContext);

    const { t, i18n } = useTranslation();
    const allowAccess = requireLogin && loggedIn;

    const navListOptions = [
        { title: "FAR Navigator", text: "Use Varyst AI's co-pilot to explain concepts, outline procedures, and interpret regulation.", value: "chat" },

        {
            title: "Acquisition Planning",
            text: "Use Varyst AI's to strategize and prepare for the acquisition of goods and services.",
            value: "acquisition"
        },
        {
            title: "Smart Contract Creation",
            text: "Use Varyst AI's smart contract wizard to create contracts with simple steps.",
            value: "contract"
        }
    ];

    const handleChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
        toggleTheme(); // Pass true or false based on the checkbox state
    };

    return (
        <div className={styles.container}>
            {/* Setting the page title using react-helmet-async */}
            <Helmet>
                <title>{t("pageTitle")}</title>
            </Helmet>

            <div className={styles.homeRoot}>
                <div className={styles.homeContainer}>
                    <div className={styles.homeContainerDefaultState}>
                        <img className={styles.homeImage} src="https://staticfilessagedev.blob.core.windows.net/web/sage-logo-md.png" />
                        <h1 className={styles.homeContainerDefaultStateTitle}>{t("chatEmptyStateTitle")}</h1>

                        {loggedIn && (
                            <>
                                <h2 className={styles.homeContainerDefaultStateTitleSubtitle}>The Contracting Officer Co-Pilot & Smart Contract Creator.</h2>

                                <ul className={styles.homeNavList}>
                                    {navListOptions.map((example, index) => (
                                        <li key={index}>
                                            <NavLink to={`/${example.value}`} className={styles.homeNavItem}>
                                                <p className={styles.homeNavItemTitle}>{example.title}</p>
                                                <span className={styles.homeNavItemText}>{example.text}</span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                    {loggedIn && (
                        <div>
                            <Checkbox checked={themeMode === "dark"} onChange={toggleTheme} label={"Dark Theme"} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
